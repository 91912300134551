import { Injectable } from '@angular/core';
import { SetPasswordValidationError } from '@latch/latch-web/lib/auth/set-password/set-password';
import { Observable, of } from 'rxjs';
import {
  GetPasswordSessionResponse, PasswordService, SetPasswordRequestSource,
  SetPasswordResponse, TokenValidityStatus
} from './password.service';

@Injectable()
export class MockPasswordService extends PasswordService {

  private _validationErrors: SetPasswordValidationError[] = [];

  set validationErrors(validationErrors: SetPasswordValidationError[]) {
    this._validationErrors = [...validationErrors];
  }

  getPasswordSession(_sessionToken: string): Observable<GetPasswordSessionResponse> {
    return of({
      tokenStatus: TokenValidityStatus.VALID,
      source: SetPasswordRequestSource.USER_INVITATION
    });
  }

  setPasswordWithToken(_sessionToken: string, _password: string, _confirmPassword: string): Observable<SetPasswordResponse> {
    return of({
      email: 'demo@latch.com',
      source: SetPasswordRequestSource.USER_INVITATION,
      validationErrors: this._validationErrors
    });
  }
}
