import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

import { APIService } from 'shared/services/api.service';

import {
  PasswordService,
  SetPasswordResponse,
  SetPasswordError,
  SetPasswordValidationError,
  SetPasswordSuccessResponse,
  GetPasswordSessionResponse,
} from './password.service';
import { map, catchError } from 'rxjs/operators';
import { getPayload, hasProperty } from '@latch/latch-web';

@Injectable()
export class HTTPPasswordService extends PasswordService {

  constructor(private apiService: APIService) {
    super();
  }

  getPasswordSession(sessionToken: string): Observable<GetPasswordSessionResponse> {
    const params = new HttpParams()
      .append('token', sessionToken);
    return this.apiService
      .request('get',
        '/web/v1/user-accounts/password/session', {}, {}, params)
      .pipe(map((response) => getPayload(response) as GetPasswordSessionResponse));
  }

  setPasswordWithToken(sessionToken: string, password: string, confirmPassword: string): Observable<SetPasswordResponse> {
    return this.apiService
      .request('post', '/web/v3/user-accounts/password', {
        sessionToken,
        password,
        confirmPassword
      }).pipe(
        map((response) => {
          const message = getPayload(response) as SetPasswordSuccessResponse;
          return {
            email: message.email,
            source: message.source,
            validationErrors: null
          };
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            const message = getPayload(error);
            if (message !== SetPasswordError.BadData && error.status === 400 && hasProperty(message, 'errorCodes')) {
              const errorCodes = message.errorCodes as SetPasswordValidationError[];
              // If this failed because the user tried to set an invalid password as their new password, return the
              // reason in a successful SetPasswordResponse. In all other error cases, fall through to the client.
              return of({
                email: null,
                source: null,
                validationErrors: errorCodes
              });
            }
          }

          return throwError(error);
        })
      );
  }
}
