import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LatchAnalyticsModule, LatchLogModule, LATCH_APPLICATION_NAME } from '@latch/latch-web';
import { environment } from 'environments/environment';
import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './components/app.component';
import { NotFoundComponent } from './components/not-found.component';
import { SetPasswordPageComponent } from './components/set-password/set-password-page.component';
import { HTTPPasswordService } from './services/password/http-password.service';
import { MockPasswordService } from './services/password/mock-password.service';
import { PasswordService } from './services/password/password.service';

const APPLICATION_NAME = 'Latch-IDWeb';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    SetPasswordPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forRoot([
      { path: 'set-password', component: SetPasswordPageComponent },
      { path: '404', component: NotFoundComponent },
      { path: '**', redirectTo: '/404' }
    ], { relativeLinkResolution: 'legacy' }),
    LatchLogModule.forRoot({
      enableLogging: environment.enableLogging,
      datadog: environment.datadogToken ? {
        datadogToken: environment.datadogToken,
        environmentName: environment.name,
        service: 'id-frontend'
      } : undefined,
    }),
    LatchAnalyticsModule.forRoot({
      enableAnalytics: environment.enableAnalytics ?? false,
      environmentName: environment.name,
      heap: environment.heapApplicationId ? {
        applicationId: environment.heapApplicationId,
        client: APPLICATION_NAME,
      } : undefined,
    }),
  ],
  providers: [
    { provide: PasswordService, useClass: environment.useMocks ? MockPasswordService : HTTPPasswordService },
    { provide: LATCH_APPLICATION_NAME, useValue: APPLICATION_NAME },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
