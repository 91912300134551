<latch-loading [isActive]="isLoading">
  <div *ngIf="currentStep === step.EnterNewPassword && !isLoading">
    <h2>Set your new password.</h2>
    <p>Your password must be at least 12 characters and include a letter, number and symbol.</p>

    <!-- `action` attribute on the form makes the return key say "Go" on iOS Safari -->
    <form
      action
      (ngSubmit)="submit()"
      [formGroup]="form"
    >
      <div class="form-group" [class.has-error]="formErrors.passwordError">
        <div class="input-group">
          <input
            type="password"
            class="form-control large-input"
            formControlName="password"
            autocomplete="off"
            placeholder="Enter new password"
            latchAutofocus
          />
        </div>
        <div class="form-control-feedback" *ngIf="formErrors.passwordError">
          {{formErrors.passwordError}}
        </div>
      </div>
      <div class="form-group" [class.has-error]="formErrors.confirmPasswordError">
        <div class="input-group">
          <input
            type="password"
            class="form-control large-input"
            formControlName="confirmPassword"
            autocomplete="off"
            placeholder="Confirm password"
          />
        </div>
        <div class="form-control-feedback" *ngIf="formErrors.confirmPasswordError">
          {{formErrors.confirmPasswordError}}
        </div>
      </div>
      <div class="actions">
        <button type="submit" class="button-primary" [disabled]="isLoading">Continue</button>
      </div>
    </form>
  </div>
  <div *ngIf="currentStep === step.Confirmation" class="success-message">
    <h2>Your new password was successfully set.</h2>
    <div class="nav-button-container">
      <a *ngIf="nextPageNavigation" class="button button-primary" [href]="nextPageNavigation.url"
        [text]="nextPageNavigation.buttonText">
      </a>
    </div>
  </div>
  <div *ngIf="currentStep === step.ExpiredToken">
    <h2>This link is expired.</h2>
    It might already have been used, or it might have been too long since the email was sent.

    Use the "forgot password" link at login to send a new email.
    <div class="nav-button-container">
      <a *ngIf="nextPageNavigation" class="button button-primary" [href]="nextPageNavigation.url"
        [text]="nextPageNavigation.buttonText">
      </a>
    </div>
  </div>
  <div *ngIf="currentStep === step.InvalidToken">
    <h2>This link is not valid.</h2>

    Check that you copied the link correctly. If you continue to experience problems, contact Latch support at support@latch.com.
  </div>
  <div *ngIf="currentStep === step.UnexpectedError">
    <h2>An unexpected error occurred.</h2>
    If you continue to experience problems, contact Latch support at support@latch.com.
  </div>
</latch-loading>
