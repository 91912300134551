/**
 * Mapping from different password validation error codes to the human-readable error message that should be displayed for that error.
 *
 * TODO: The codes themselves are defined in SetPasswordValidationValidationError. We should probably figure out how to pull those into a
 * common place as well, though a presentation file is not the sensible answer.
 */
export const PasswordValidationErrorMessages = {
  CONFIRM_PASSWORD_MISMATCH: 'Sorry, your password entries do not match.',
  INSUFFICIENT_ALPHABETICAL: 'Sorry, your password must have at least one alphabetical character.',
  INSUFFICIENT_DIGIT: 'Sorry, your password must have at least one numeric character.',
  INSUFFICIENT_SPECIAL: 'Sorry, your password must have at least one special character.',
  ILLEGAL_MATCH: 'Sorry, your password must not repeat a character more than 2 times.',
  ILLEGAL_WHITESPACE: 'Sorry, your password must not contain whitespace.',
  TOO_SHORT: 'Sorry, your password must be at least 12 characters.',
  TOO_LONG: 'Sorry, your password must be no more than 1024 characters.',
  ILLEGAL_USERNAME: 'Sorry, your password must not contain a variant of your email.',
  ILLEGAL_USERNAME_REVERSED: 'Sorry, your password must not contain a variant of your email.',
  HISTORY_VIOLATION: 'Sorry, you cannot re-use any of your last five passwords.',
  ILLEGAL_WORD: 'Please select a stronger password and try again.',
  INCORRECT_CREDENTIALS: 'Sorry, that password is incorrect. Please check your entry and try again.'
};

/**
 * The error types that we can receive from the backend when we attempt to login.
 *
 * The string value of each value is the error code returned by the backend (with the exception of UnexpectedError,
 * which is not a real backend error code, it is a placeholder for an error that does not fit one of the other,
 * expected types).
 */
export const enum LoginError {
  InvalidEmailOrPassword = 'INVALID_EMAIL_OR_PASSWORD',
  UserAccountTemporarilyBlocked = 'USER_ACCOUNT_TEMPORARILY_BLOCKED',
  SessionRequiresAdminRole = 'SESSION_REQUIRES_ADMIN_ROLE',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

/**
 * The user-facing error message we should display for each of the login error types.
 */
export const LoginErrorMessages = {
  [LoginError.InvalidEmailOrPassword]: 'Your email or password is incorrect.',
  [LoginError.UserAccountTemporarilyBlocked]: 'This account has been blocked because of too many failed login attempts. ' +
    'Try again in ten minutes.',
  /**
   * Exact same copy exists in ErrorHandlerService for AppError.UserHasNoBuildings, and when we unify error messaging this is one of the
   * ones that should be unified. For now, this one is shown if the user has NO management permission, and the ErrorHandlerService one
   * is shown when the user has management permission, but without any actual buildings.
   */
  [LoginError.SessionRequiresAdminRole]: 'You do not have permission to manage any portfolios or properties.',
  [LoginError.UnexpectedError]: 'An unexpected error occurred. If this problem persists, please contact support@latch.com.'
};

/**
 * mapping from different mfa validation error codes to human-readable error messages that should be displayed for that error
 *
 */
export const MfaValidationErrorMessages = {
  MFA_INVALID_TOKEN: 'The code you entered is invalid, please try again.',
  MFA_NOT_REQUIRED: 'You cannot verify because Two-Factor Authentication is not enabled on your account',
  MFA_ALREADY_VERIFIED: 'You have already enabled Two-Factor Authentication',
  INVALID_EMAIL_OR_PHONE_NUMBER: 'The email or phone number you provided is invalid.',
  MFA_REQUIRED_FOR_ACCOUNT: 'MFA is required for your account.',
  MFA_TOO_MANY_REQUESTS: 'Your account is temporarily locked due to too many failed attempts. Please try again in a little while.',
  INVALID_PHONE_NUMBER: 'The phone number provided is not valid.',
  MFA_CREDENTIALS_IN_USE: 'The phone number you provided is already in use.',
};

export const MfaStatusMessageText = {
  VERIFY: 'Authenticating your code . . .',
  SEND: 'Sending authentication code . . .',
  CHANGE: 'Updating your Two-Factor Authentication settings . . .',
  PHONE: 'Saving phone number . . .'
};

export const AccountAdministrationErrorMessages = {
  USER_IS_AN_ACCOUNT_ADMINISTRATOR_ALREADY: 'This user could not be added because they are already a property manager on this account.',
  INVALID_EMAIL_ERROR: 'The email you provided is not valid.',
};

export const DefaultValidationErrorMessage = 'An unexpected error occurred. If this problem persists, please contact support@latch.com.';


export const enum StatusMessageType {
  INFO,
  ERROR
}

export class MfaStatusMessage {
  type: StatusMessageType = StatusMessageType.INFO;
  msg: string | undefined;

  constructor(type: StatusMessageType, errorCode: string) {
    this.type = type;
    this.msg = this.getMessage(errorCode);
  }

  isError() {
    return this.type === StatusMessageType.ERROR;
  }

  private getMessage(str: string): string {
    if (isValidMfaValidationMessageKey(str)) {
      return MfaValidationErrorMessages[str];
    }

    if (isValidMfaStatusMessageKey(str)) {
      return MfaStatusMessageText[str];
    }
    return '';
  }
}

function isValidMfaValidationMessageKey(
  str: string
): str is keyof typeof MfaValidationErrorMessages {
  return str in MfaValidationErrorMessages;
}

function isValidMfaStatusMessageKey(
  str: string
): str is keyof typeof MfaStatusMessageText {
  return str in MfaStatusMessageText;
}
