import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isDefined } from '@latch/latch-web';

export enum TokenValidityStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  EXPIRED = 'EXPIRED',
  REVOKED = 'REVOKED',
  /** TOKEN_MISSING included here in order to document all potential states */
  TOKEN_MISSING = 'Missing parameter: token'
}

/** The reason a set-password token was created in the first place. Used to redirect user after setting password. */
export enum SetPasswordRequestSource {
  /** Set password link was in an invite email informing a user that they were created as an admin. */
  ADMIN_INVITATION = 'ADMIN_INVITATION',
  /** Set password link was in an invite email informing a user that they were created as a resident. */
  USER_INVITATION = 'USER_INVITATION',
  /** Set password link was in a reset email sent in response to "forgot password" in the mobile app. */
  APP_RESET = 'APP_RESET',
  /** Set password link was in a reset email sent in response to "forgot password" in MW. */
  WEB_RESET = 'WEB_RESET',
  /** Set password link was in an invite email for a primary resident on a lease who doesn't yet have a latch account. */
  LEASE_INVITATION = 'LEASE_INVITATION',
  /** Set password link was a password reset originating in resident portal. */
  LEASE_RESET = 'LEASE_RESET'
}

export interface GetPasswordSessionResponse {
  tokenStatus: TokenValidityStatus;
  source: SetPasswordRequestSource;
}

/**
 * Data structure returned by setPasswordWithToken when the call succeeds, or when it fails because of an invalid
 * choice of password.
 */
export type SetPasswordResponse = SetPasswordSuccessResponse | SetPasswordErrorResponse;

export interface SetPasswordSuccessResponse {
  /**
   * The email of the user that just reset their password.
   *
   * Can be used to pre-populate that field in the login form if desired.
   */
  email: string;
  /**
   * The source from which this email came.
   *
   * Used to decide where to direct the user after successful reset (to an app, to MW).
   */
  source: SetPasswordRequestSource;
}

export interface SetPasswordErrorResponse {
  /**
   * List of problems with the user's desired password.
   *
   * When a user's attempt to set their password fails because of validation errors, this array will be populated
   * and email and source will be empty.
   */
  validationErrors: SetPasswordValidationError[];
}

export function isSetPasswordErrorResponse(value: SetPasswordResponse): value is SetPasswordErrorResponse {
  return isDefined((value as SetPasswordErrorResponse).validationErrors);
}

/** Error codes returned from backend when attempting to set a password using a token from a link in an email. */
export enum SetPasswordError {
  BadData = 'BAD_DATA',
  /**
   * Token provided came from an invitation to a new user and has expired (has already been used, or too much time
   * passed since its creation).
   *
   * We differentiate this error from ResetTokenExpired because the user messaging is different - in the case of a
   * forgot password request, the user can go hit forgot password again. In the case of an invite, they need to
   * ask their manager to send them another invite email.
   */
  InviteTokenExpired = 'INVITE_PASSWORD_TOKEN_EXPIRED',
  /**
   * Token provided came from a "forgot password" request and has expired (has already been used, or too much time
   * passed since its creation).
   *
   * We differentiate this error from InviteTokenExpired because the user messaging is different - in the case of a
   * forgot password request, the user can go hit forgot password again. In the case of an invite, they need to
   * ask their manager to send them another invite email.
   */
  ResetTokenExpired = 'RESET_PASSWORD_TOKEN_EXPIRED',
  /** Token provided is not a token in our system (not that it's expired - it's just not a token). */
  TokenInvalid = 'PASSWORD_TOKEN_INVALID'
}

/** Errors returned by the backend specifying why an InvalidPassword error was returned. */
export enum SetPasswordValidationError {
  /** password and confirmPassword in the request do not match. */
  ConfirmPasswordMismatch = 'CONFIRM_PASSWORD_MISMATCH',
  /** proposed password contains 3 or more of the same character in a row */
  RepeatedCharacters = 'ILLEGAL_MATCH',
  /** proposed password is fewer than 12 characters */
  TooShort = 'TOO_SHORT',
  /** proposed password is more than 1024 characters */
  TooLong = 'TOO_LONG',
  /** proposed password contains username of user's email */
  IllegalUsername = 'ILLEGAL_USERNAME',
  /** proposed password contains username of user's email, but in reverse */
  IllegalUsernameReversed = 'ILLEGAL_USERNAME_REVERSED',
  /** proposed password contains a commonly used word like 'password' */
  IllegalWord = 'ILLEGAL_WORD'
}

@Injectable()
export abstract class PasswordService {
  /**
   * Attempts to set the password of the user associated with the given token.
   *
   * If the request fails because of one of the reasons in SetPasswordError, an error will be thrown as a HttpErrorRespones
   * with one of those codes in they payload's message.
   * If the request fails because of problems with the user's provided password, the success handler will be
   * called, but the SetPasswordResponse will have validationErrors instead of email or source.
   *
   * TODO: is there a better way to do error handling? This is the model followed by the key update endpoints.
   * Seems preferable to have typed errors (could we return our own custom error class with a type-enforced
   * error type, and an optional array of validation errors?) But the Observable Typescript bindings don't
   * let us specify the type of the error thrown.
   *
   * @param sessionToken token which user received in email inviting them to reset their password
   * @param password desired new password for user
   * @param confirmPassword desired new password for user, re-typed
   */
  abstract setPasswordWithToken(sessionToken: string, password: string, confirmPassword: string): Observable<SetPasswordResponse>;

  abstract getPasswordSession(sessionToken: string): Observable<GetPasswordSessionResponse>;
}
