import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

const ANDROID_RE = /android/i;
const IOS_RE = /iPad|iPhone|iPod/i;

// taken from vars.scss
const BREAKPOINT_LG = '1200px';
const BREAKPOINT_MD = '992px';
const BREAKPOINT_SM = '810px';

// TODO: Some duplicated code between here and Keycard's PlatformService. The only differences between this and that version are:
// 1) the addition of linkToManagerWeb - however, that function depends on environment, which is defined within each app, so is
//    difficult to share across the apps.
// 2) the URL returned for linkToLatchApp is different in the case where the user is on desktop

@Injectable({ providedIn: 'root' })
export class PlatformService {
  get isMediaSmall() {
    return window.matchMedia(`(max-width: ${BREAKPOINT_SM})`).matches;
  }

  get isMediaMedium() {
    return window.matchMedia(`(max-width: ${BREAKPOINT_MD})`).matches;
  }

  get isMediaLarge() {
    return window.matchMedia(`(max-width: ${BREAKPOINT_LG})`).matches;
  }

  get isAndroid() {
    return ANDROID_RE.test(navigator.userAgent);
  }

  get isIOS() {
    return IOS_RE.test(navigator.userAgent);
  }

  get isMobile() {
    return this.isMediaSmall;
  }

  get isTablet() {
    return !this.isMediaSmall && this.isMediaMedium;
  }

  get isDesktop() {
    return !this.isMediaSmall && !this.isMediaMedium;
  }


  get linkToLatchApp() {
    if (this.isAndroid) {
      return 'https://play.google.com/store/apps/details?id=com.latch.android.latchapp&hl=en';
    } else if (this.isIOS) {
      return 'https://itunes.apple.com/us/app/latch-app/id1062355375';
    } else {
      return 'https://support.latch.com/hc/en-us/categories/115001572767-Living-with-Latch';
    }
  }

  get linkToManagerWeb() {
    if (environment.name === 'local') {
      return 'http://localhost:4200';
    } else if (environment.name === 'dev-no-logs' || environment.name === 'mock') {
      return 'https://manager-dev.latchaccess.com';
    } else if (environment.name === 'production') {
      return 'https://manager.latch.com';
    } else if (environment.name === 'integrations-test') {
      return 'https://manager-integrations-test.latch.com';
    } else {
      return `https://manager-${environment.name}.latchaccess.com`;
    }
  }

  get linkToResidentPortal() {
    if (environment.name === 'local') {
      return 'http://localhost:3000';
    } else if (environment.name === 'dev-no-logs' || environment.name === 'mock') {
      return 'https://resident.dev.latchaccess.com';
    } else if (environment.name === 'production') {
      return 'https://resident.latch.com';
    } else {
      return `https://resident.${environment.name}.latchaccess.com`;
    }
  }
}
