import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  PasswordService,
  SetPasswordRequestSource,
  SetPasswordError,
  SetPasswordValidationError,
  TokenValidityStatus,
  isSetPasswordErrorResponse,
} from '../../services/password/password.service';
import { PlatformService } from '../../services/platform.service';
import { PasswordValidationErrorMessages } from '../../../shared/utility/presentation';
import { HttpErrorResponse } from '@angular/common/http';
import {
  getPayload,
  hasValidPayload,
  isDefined,
  logError,
  LatchAnalyticsService,
  LatchAnalyticsConstants
} from '@latch/latch-web';

enum Step {
  EnterNewPassword,
  Confirmation,
  UnexpectedError,
  InvalidToken,
  ExpiredToken
}

interface FormErrors {
  passwordError?: string;
  confirmPasswordError?: string;
}

interface NextPageNavigation {
  url: string;
  buttonText: string;
}

@Component({
  selector: 'latch-set-password-page',
  templateUrl: './set-password-page.component.html',
  styleUrls: ['./set-password-page.component.scss']
})
export class SetPasswordPageComponent implements OnInit {
  /** Form containing all fields. Used only to detect value changes. */
  form!: FormGroup;
  /** Human readable validation errors for each form field. */
  formErrors: FormErrors = {};

  /** Password currently entered in the password form field. */
  get password(): string {
    return this.form.get('password')?.value || '';
  }
  /** Password currently entered in the confirm password form field. */
  get confirmPassword(): string {
    return this.form.get('confirmPassword')?.value || '';
  }

  /** The token that the user will attempt to use to submit the set password request. */
  token = '';
  passwordSessionSource: SetPasswordRequestSource | undefined;

  /** Copy of Step enum so that values can be accessed in template. */
  step = Step;
  /** The step that the user is at in the set password process. */
  currentStep = Step.EnterNewPassword;

  /** This will be set using the server's response to our request - we should only assume it is set when currentStep is Confirmation. */
  requestSource: SetPasswordRequestSource | undefined;

  /** The page a user is navigated to after setting their password. */
  nextPageNavigation: NextPageNavigation | undefined;

  /** True when there is an in-flight request to the server. */
  isLoading = false;

  constructor(
    private passwordService: PasswordService,
    private activatedRoute: ActivatedRoute,
    public platformService: PlatformService,
    private analyticsService: LatchAnalyticsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Set Password'
    });

    const { queryParams } = this.activatedRoute.snapshot;
    this.token = queryParams.token;
    if (!this.token) {
      this.currentStep = Step.InvalidToken;
      return;
    }

    // We only use this group to detect any form changes and clear errors.
    this.form = this.formBuilder.group({ password: [''], confirmPassword: [''] });
    // Clear all errors any time the user changes any field.
    this.form.valueChanges.subscribe(() => this.formErrors = {});
    this.isLoading = true;
    this.passwordService.getPasswordSession(this.token)
      .subscribe((session) => {
        this.passwordSessionSource = session.source;

        switch (session.tokenStatus) {
          case TokenValidityStatus.VALID:
            this.currentStep = Step.EnterNewPassword;
            break;
          case TokenValidityStatus.INVALID:
            this.currentStep = Step.InvalidToken;
            break;
          case TokenValidityStatus.EXPIRED:
          case TokenValidityStatus.REVOKED:
            this.currentStep = Step.ExpiredToken;
            break;
          default:
            throw new Error('Unsupported token status');
        }

        this.setNextPageNavigation(session.source, this.currentStep);
        this.isLoading = false;
      }, (error) => {
        this.currentStep = Step.UnexpectedError;
        logError(`Error getting password session for token ${this.token}: ${error}`);
        this.isLoading = false;
      });
  }

  private setNextPageNavigation(source: SetPasswordRequestSource, step: Step) {
    switch (step) {
      case Step.Confirmation:
        this.setNextPageNavigationSuccess(source);
        break;
      case Step.ExpiredToken:
        this.setNextPageNavigationFailure(source);
        break;
      case Step.EnterNewPassword:
        // no-op
        break;
      case Step.UnexpectedError:
      case Step.InvalidToken:
          // no password session found -> nowhere to navigate
          break;
      default:
        throw new Error('Unknown step');
    }
  }

  private setNextPageNavigationSuccess(source: SetPasswordRequestSource) {
    switch (source) {
      case SetPasswordRequestSource.ADMIN_INVITATION:
      case SetPasswordRequestSource.WEB_RESET:
        this.setManagerWebNavigation();
        break;
      case SetPasswordRequestSource.USER_INVITATION:
      case SetPasswordRequestSource.APP_RESET:
        this.setLatchAppNavigation();
        break;
      case SetPasswordRequestSource.LEASE_INVITATION:
        this.setResidentPortalNavigation(true);
        break;
      case SetPasswordRequestSource.LEASE_RESET:
        this.setResidentPortalNavigation(false);
        break;
      default:
        throw new Error('Unsupported set password request source');
    }
  }

  private setNextPageNavigationFailure(source: SetPasswordRequestSource) {
    switch (source) {
      case SetPasswordRequestSource.ADMIN_INVITATION:
      case SetPasswordRequestSource.WEB_RESET:
        this.setManagerWebNavigation();
        break;
      case SetPasswordRequestSource.USER_INVITATION:
      case SetPasswordRequestSource.APP_RESET:
        // no-op
        break;
      case SetPasswordRequestSource.LEASE_INVITATION:
      case SetPasswordRequestSource.LEASE_RESET:
        this.setResidentPortalNavigation(false);
        break;
      default:
        throw new Error('Unsupported set password request source');
    }
  }

  private setManagerWebNavigation() {
    this.nextPageNavigation = {
      url: this.platformService.linkToManagerWeb,
      buttonText: 'Login'
    };
  }

  private setLatchAppNavigation() {
    const isMobile = this.platformService.isAndroid || this.platformService.isIOS;
    this.nextPageNavigation = {
      url: this.platformService.linkToLatchApp,
      buttonText: isMobile ? 'Download Latch App' : 'Learn More'
    };
  }

  private setResidentPortalNavigation(isFirstSetPasswordRequest: boolean) {
    this.nextPageNavigation = {
      url: this.platformService.linkToResidentPortal,
      buttonText: isFirstSetPasswordRequest ? 'Get started' : 'Login'
    };
  }

  submit() {
    // Backend returns an error response of BAD_DATA instead of a validation error if one of the passwords is blank (this is
    // because of some stuff that happens early in their input-parsing pipeline). So rather than taking the user to an error
    // page, remind them to fill those fields in.
    if (this.password.length === 0) {
      this.buildHumanReadableErrors([SetPasswordValidationError.TooShort]);
      return;
    } else if (this.confirmPassword.length === 0) {
      // We know that this.password is not an empty string, but confirmPassword is.
      this.buildHumanReadableErrors([SetPasswordValidationError.ConfirmPasswordMismatch]);
      return;
    }

    this.isLoading = true;
    this.passwordService.setPasswordWithToken(this.token, this.password, this.confirmPassword)
      .subscribe((result) => {
        this.isLoading = false;

        if (isSetPasswordErrorResponse(result)) {
          this.buildHumanReadableErrors(result.validationErrors);
        } else {
          this.requestSource = result.source;
          this.currentStep = Step.Confirmation;
          this.setNextPageNavigation(result.source, this.currentStep);

          this.trackSuccess();
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;

        if (hasValidPayload(error)) {
          const payload = getPayload(error) as SetPasswordError;
          if (payload === SetPasswordError.BadData) {
            this.currentStep = Step.UnexpectedError;
          } else if (payload === SetPasswordError.InviteTokenExpired || payload === SetPasswordError.ResetTokenExpired) {
            this.currentStep = Step.ExpiredToken;
          } else if (payload === SetPasswordError.TokenInvalid) {
            this.currentStep = Step.InvalidToken;
          } else {
            this.currentStep = Step.UnexpectedError;
          }
          if (isDefined(this.passwordSessionSource)) {
            this.setNextPageNavigation(this.passwordSessionSource, this.currentStep);
          }
          this.trackError(payload);
        }
      });
  }

  /**
   * Takes validation errors as returned from the server and converts them into human-readable strings, storing those strings on formErrors.
   *
   * @param validationErrors The validation errors with the password & confirmPassword the user attempted to set.
   */
  public buildHumanReadableErrors(validationErrors: SetPasswordValidationError[]): void {
    this.formErrors = {};

    const passwordErrors = validationErrors.filter((validationError) =>
      // Currently the only error that we want to surface on the confirm password field is password mismatch.
      validationError !== SetPasswordValidationError.ConfirmPasswordMismatch
    );
    const confirmPasswordErrors = validationErrors.filter(
      (validationError) => validationError === SetPasswordValidationError.ConfirmPasswordMismatch
    );

    if (passwordErrors.length > 0) {
      // We only surface the first error on each field.
      this.formErrors.passwordError = PasswordValidationErrorMessages[passwordErrors[0]];
    }
    if (confirmPasswordErrors.length > 0) {
      this.formErrors.confirmPasswordError = PasswordValidationErrorMessages[confirmPasswordErrors[0]];
    }
  }

  trackSuccess() {
    this.analyticsService.track('Set Password', {
      [LatchAnalyticsConstants.Success]: true
    });
  }

  trackError(message: string) {
    this.analyticsService.track('Set Password', {
      [LatchAnalyticsConstants.Success]: false,
      [LatchAnalyticsConstants.ErrorMessage]: message
    });
  }
}
